//  defaults
html,body{height: 100%;color: $gray800;line-height: 1.4;font-family: 'Lato', sans-serif;font-weight:400;scroll-behavior: smooth;}
img,video{max-width: 100%;height: auto;vertical-align:middle;}

h1, h2, h3, h4{font-family: 'Castoro', serif;font-weight: 400;}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{color: inherit;font-size: inherit;}
button{
  border: none;
  &:hover{outline: none;cursor: pointer;}
  &:active{outline: none;}
  &:focus{outline: none;}
}
ul,ol{padding: 0;margin: 0;
  li{list-style-type: none;}
}
//container//
.container {
  max-width: 1680px;
  padding: 0 60px;
  @include media-breakpoint-down(lg){
    max-width: 960px;
    padding: 0;
  }
  @include media-breakpoint-down(md){
    max-width: 720px;
  }
  @include media-breakpoint-down(sm){
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
//container end//
//font size//
a   {text-decoration: none !important;}
p   {font-size: 16px; color: $p-clr; line-height: 28px; margin: 0; @include media-breakpoint-down(md) {font-size: 14px; line-height: 24px;}}
h1  {font-size: 36px; font-weight: 600;}
h2  {font-size: 34px;}
h3  {font-size: 30px;}
h4  {font-size: 28px;}
h5  {font-size: 26px;}
h6  {font-size: 24px;}
//section area//
.section-area{
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
  @include media-breakpoint-down(lg) {
    padding: 60px 0;
  }
  @include media-breakpoint-down(md) {
    padding: 40px 0;
  }
}
//section area end//
//order//
.my-order-1{
  @include media-breakpoint-down(sm){
    order: 1;
  }
}
.my-order-2{
  @include media-breakpoint-down(sm){
    order: 2;
  }
}
.my-order-3{
  @include media-breakpoint-down(sm){
    order: 3;
  }
}
.my-order-4{
  @include media-breakpoint-down(sm){
    order: 4;
  }
}
//order end//
//owl carousel//
.owl-carousel {
  text-align: center;
  .owl-stage-outer {
    padding: 0;
  }
  .owl-nav {
    .owl-prev {
      position: absolute;
      left: 0;
      top: 23%;
      @include media-breakpoint-down(lg){
        top: 19%;
      }
      @include media-breakpoint-down(md){
        top: 22%;
      }
      @include media-breakpoint-down(sm){
        top: 20%;
      }
    }
    .owl-next {
      position: absolute;
      right: 0;
      top: 23%;
      @include media-breakpoint-down(lg){
        top: 19%;
      }
      @include media-breakpoint-down(md){
        top: 22%;
      }
      @include media-breakpoint-down(sm){
        top: 20%;
      }
    }
    button {
      background-color: transparent !important;
      span {
        font-size: 70px;
        color: $dark;
        background: transparent;
        font-weight: 300;
        transition: all 0.5s;
        @include media-breakpoint-down(md) {
          font-size: 60px;
        }
        &:focus, &:active, &:hover {
          outline: none;
          transition: all 0.5s;
        }
      }
    }
    button:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
  .owl-nav:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .owl-nav{
    margin-top: 0;
  }
  .owl-dots {
    margin-top: -28px;
    .owl-dot{
      span{
        background-color: $light-clr !important;
      }
      &.active{
        span{
          background-color: $main-clr !important;
        }
      }
      &:hover, &:active, &:focus{
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
  }
}
//owl carousel end//
//pagination//
.my-pagination {
  padding: 0;
  margin: 0;
  text-align: center;
  .pagination {
    margin-bottom: 0;
    justify-content: center;
    .page-item {
      a {
        color: $main-clr;
        border: none;
        background-color: $light-clr;
        margin-right: 6px;
        border-radius: 4px;
        padding: 10px 15px;
        transition: all 0.7s;
        @include media-breakpoint-down(md) {
          padding: 8px 11px;
          font-size: 14px;
        }
        &:focus, &.active, &:hover{
          transition: all 0.7s;
          text-decoration: none;
          box-shadow: none;
          outline: none;
          border: none;
        }
      }
      &:focus, &.active, &:hover {
        a {
          transition: all 0.7s;
          color: $white;
          text-decoration: none;
          background-color: $main-clr;
          box-shadow: none;
          outline: none;
          border: none;
        }
      }
    }
  }
}
//pagination end//
//stars//
.stars {
  li {
    display: inline-block !important;
    align-items: center;
    a{
      i{
        font-size: 15px;
        color: $s-main-clr;
        margin-right: 1px;
        @include transition;
      }
    }
    &.active{
      a{
        i{
          color: $secondary-color;
        }
      }
    }
  }
}
//stars end//