.news-details {
  text-align: left;
  margin-bottom: 50px;
  @include transition;
  @include media-breakpoint-down(md){
    margin-bottom: 40px;
  }
  @include media-breakpoint-down(sm){
    margin-bottom: 30px;
  }
  .news-img {
    margin-bottom: 20px;
    @include media-breakpoint-down(md){
      margin-bottom: 16px;
    }
    img {
      width: 100%;
    }
  }
  .news-date {
    padding: 0;
    margin: 0;
    @include media-breakpoint-down(md) {
      padding: 5px 0 0 0;
    }
    li {
      display: inline-block;
      margin: 0 16px 12px 0;
      @include media-breakpoint-down(md) {
        margin: 0 8px 8px 0;
      }
      a {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $dark;
        @include transition;
        i {
          font-size: 16px;
          color: $main-clr;
          @include transition;
          margin-bottom: 4px;
        }
        &:hover{
          color: $main-clr;
          i{
            color: $main-clr;
          }
        }
      }
    }
  }
  .news-text {
    padding: 0;
    h3 {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $dark;
      margin-bottom: 0;
      @include transition;
      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }
    }
    .news-p{
      margin: 12px 0;
    }
    .read-btn {
      font-size: 16px;
      font-weight: 600;
      color: $main-clr;
      transition: all 0.7s;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
      i {
        font-size: 16px;
        color: $main-clr;
        transition: all 0.7s;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
      &:hover {
        margin-left: 5px;
        transition: all 0.7s;
        i {
          transition: all 0.7s;
        }
      }
    }
  }
  &:hover{
    .news-text{
      h4{
        color: $dark;
      }
    }
  }
}