//primary gradient
@mixin primary-linear-gradient() {
  background: $primary;
  background: linear-gradient(to right, $primary 0%,$primarydark 100%);
}
// mixin
@mixin transition($property:all){
  transition: $property .7s ease;
}
@mixin translateXmiddle{
  left: 50%;
  transform: translateX(-50%);
}
@mixin translateYmiddle{
  top: 50%;
  transform: translateY(-50%);
}
@mixin translatemiddle{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
@mixin objectfit($height,$object:cover,$position:top,$width:100%){
  height: $height;
  object-fit: $object;
  object-position: $position;
  width: $width;
}