//footer//
footer {
  width: 100%;
  overflow: hidden;
  padding: 50px 0 0 0;
  background-image: url("../../assets/image/banner/f-background-01.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    padding: 30px 0 0 0;
  }
  .f-logo{
    width: 250px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md){
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm){
      width: 230px;
      margin-bottom: 10px;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $dark;
    @include media-breakpoint-down(md) {
      font-size: 19px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      margin-top: 10px;
      padding-top: 20px;
      border-top: 1px solid #00000024;
    }
  }
  .contacts {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      border: none;
    }
  }
  ul {
    margin-top: 40px;
    padding: 0;
    @include media-breakpoint-down(md){
      margin-top: 25px;
    }
    li {
      list-style-type: none;
      margin-bottom: 6px;
      .my-ul{
        padding: 0;
        margin: 0;
        li{
          display: inline-block;
        }
      }
      .my-ul-img{
        li{
          display: inline-block;
          a{
            img{
              height: 18px;
            }
          }
        }
      }
      a {
        font-size: 15.5px;
        letter-spacing: 1px;
        color: $dark;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
        &:hover{
          color: $dark;
        }
        img{
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }
  .my-links {
    padding-left: 35px;
    @include media-breakpoint-down(lg) {
      padding-left: 25px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
    a{
      color: $dark;
      @include transition;
      &:hover{
        color: $dark;
        margin-left: 6px;
      }
    }
  }
  .my-col-6{
    @include media-breakpoint-down(md){
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
  }
  .footer-bottom {
    margin-top: 85px;
    padding: 15px 0;
    background-image: url("../../assets/image/banner/f-background-02.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    @include media-breakpoint-down(md){
      margin-top: 50px;
    }
    p {
      color: $white;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      @include media-breakpoint-down(md) {
        font-size: 12.5px;
        line-height: 18px;
      }
      a {
        color: $white !important;
      }
    }
  }
}
//footer end//