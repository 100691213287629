//Please import All plugins in plugins.scss file
@import "./plugins";
*{
  font-family: 'Lato', sans-serif;
}
//banner area//
.banner-area{
  .banner-text {
    width: 100%;
    @include media-breakpoint-down(sm){
      padding: 15px;
    }
    .overlay-img {
      position: relative;
      width: 100%;
      .banner-img {
        width: 100%;
        img{
          width: 100%;
        }
      }
      .text {
        position: absolute;
        top: 28%;
        right: 10px;
        text-align: left;
        padding: 0;
        margin: 0;
        width: 55%;
        li {
          list-style-type: none;
          a {
            h3{
              font-size: 26px;
              font-weight: 600;
              color: $dark;
              margin-bottom: 15px;
              letter-spacing: 2px;
              text-transform: uppercase;
              @include media-breakpoint-down(lg){
                font-size: 20px;
                margin-bottom: 7px;
              }
              @include media-breakpoint-down(md){
                font-size: 16px;
                margin-bottom: 5px;
              }
            }
          }
          p{
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            color: $dark;
            margin-bottom: 0;
            letter-spacing: 1px;
            @include media-breakpoint-down(lg){
              font-size: 11px;
              line-height: 15px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(sm){
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 1px;
            }
          }
        }
      }
      .my-text{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 30px;
        margin: 0;
        width: 50%;
        @include media-breakpoint-down(lg){
          width: 54%;
        }
        @include media-breakpoint-down(md){
          width: 70%;
        }
        @include media-breakpoint-down(sm){
          width: 100%;
        }
        li{
          a{
            h2{
              font-size: 34px;
              font-weight: 500;
              color: $white;
              margin-bottom: 20px;
              letter-spacing: 3px;
              text-transform: uppercase;
              @include media-breakpoint-down(lg){
                font-size: 28px;
                margin-bottom: 10px;
              }
              @include media-breakpoint-down(md){
                font-size: 20px;
                margin-bottom: 8px;
              }
            }
            h6{
              font-size: 14.5px;
              font-weight: 400;
              line-height: 20px;
              color: $white;
              letter-spacing: 2px;
              text-transform: uppercase;
              font-family: 'Castoro', serif;
              margin-bottom: 60px;
              @include media-breakpoint-down(lg){
                margin-bottom: 40px;
              }
              @include media-breakpoint-down(md){
                margin-bottom: 30px;
              }
              span{
                text-transform: lowercase;
              }
            }
          }
          p{
            color: $white;
            margin-bottom: 55px;
            @include media-breakpoint-down(md){
              margin-bottom: 30px;
            }
          }
          .read-more{
            color: $dark !important;
            background-color: $white;
            @include transition;
            &.active, &:focus, &:hover {
              background-color: $main-clr;
            }
          }
        }
      }
    }
  }
}
//banner area end//

//pro area//
.pro-area{
  text-align: center;
  .pro-text{
    margin-top: 30px;
    @include media-breakpoint-down(lg) {
      margin-top: 25px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 30px;
    }
    .my-heading {
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
      h1 {
        @include media-breakpoint-down(lg) {
          letter-spacing: 2px;
          font-size: 25px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 26px;
        }
      }
    }
    p{
      font-size: 14px;
      color: $dark;
      letter-spacing: 1px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 50px;
      @include media-breakpoint-down(lg){
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 35px;
      }
    }
  }
}
//pro area end//

//solutions//
.solutions{
  background-color: $light-clr;
  text-align: center;
  width: 100%;
  overflow: hidden;
  padding: 100px 0;
  @include media-breakpoint-down(lg) {
    padding: 80px 0;
  }
  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }
  .my-ul{
    padding: 0;
    margin: 0;
    width: 100%;
    .my-li{
      display: inline-block;
      width: 18%;
      margin-top: 70px;
      @include media-breakpoint-down(lg){
        margin-top: 50px;
      }
      @include media-breakpoint-down(sm){
        width: 47%;
        margin-top: 30px;
      }
      .solutions-icon{
        img{
          height: 100px;
          @include media-breakpoint-down(lg){
            height: 80px;
          }
          @include media-breakpoint-down(md){
            height: 75px;
          }
        }
        a{
          h6{
            font-size: 18px ;
            font-weight: 600;
            line-height: 20px;
            color: $dark;
            letter-spacing: 2px;
            margin: 30px 0 0 0;
            @include transition;
            @include media-breakpoint-down(lg){
              margin: 20px 0 0 0;
              font-size: 16.5px
            }
            @include media-breakpoint-down(md){
              margin: 15px 0 0 0;
              font-size: 16px
            }
          }
          &:hover{
            h6{
              color: $main-clr;
            }
          }
        }
      }
    }
  }
}
//solutions end//

//membership//
.membership{
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-color: $l-main-clr;
  .membership-text{
    background-image: url("../../assets/image/icon/icon-15.png");
    background-size: 10%;
    background-position: bottom right;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 30px 0;
    @include media-breakpoint-down(md){
      padding: 20px 0;
    }
    @include media-breakpoint-down(sm){
      background-size: 30%;
    }
  }
  h3{
    font-size: 30px;
    color: $s-main-clr;
    letter-spacing: 3px;
    margin-bottom: 15px;
    @include media-breakpoint-down(lg){
      font-size: 26px;
    }
    @include media-breakpoint-down(md){
      font-size: 24px;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(sm){
      font-size: 22px;
    }
  }
  .read-more{
    color: $dark !important;
    background-color: $white;
    @include transition;
    &:hover{
      background-color: $main-clr;
    }
  }
}
//membership end//

//kidzparadize//
.kidzparadize{
  text-align: center;
  h6{
    font-size: 18px;
    font-weight: 600;
    color: $s-main-clr;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 60px;
    @include media-breakpoint-down(lg){
      margin-bottom: 50px;
    }
    @include media-breakpoint-down(md){
      font-size: 16px;
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }
  }
  .banner-carousel{
    .my-item{
      margin: 0 15px;
      @include media-breakpoint-down(lg){
        margin: 0 10px;
      }
      @include media-breakpoint-down(sm){
        margin: 0 4px;
      }
    }
  }
}
//kidzparadize end//

//all logo//
.all-logo{
  background-color: $light-clr;
  text-align: center;
  width: 100%;
  overflow: hidden;
  padding: 50px 0;
  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
  .my-heading{
    margin-bottom: 50px;
    @include media-breakpoint-down(md){
      margin-bottom: 30px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 10px;
    }
  }
  .my-row{
    flex-flow: row;
    @include media-breakpoint-down(sm){
      padding-top: 20px;
    }
  }
  a{
    @include transition;
    .logo-img{
      height: 60px;
      @include transition;
      @include media-breakpoint-down(md){
        height: 45px;
      }
    }
    &:hover{
      .logo-img{
        transform: translateY(-7px);
      }
    }
  }
}
//all logo end//

//awards area//
.awards-area{
  background-color: $white;
  .awards-carousel{
    .item{
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 10px 20px;
      @include media-breakpoint-down(sm){
        padding: 15px 0 10px 0;
      }
      a{
        @include transition;
        .awards-img{
          width: auto;
          @include transition;
          height: 95px;
          @include media-breakpoint-down(md){
            height: 70px;
          }
          @include media-breakpoint-down(sm){
            height: 65px;
          }
        }
        &:hover{
          .awards-img{
            transform: translateY(-7px);
          }
        }
      }
    }
    .owl-dots{
      .owl-dot{
        span{
          width: 8px;
          height: 8px;
          margin: 2px 4px;
          background-color: #dadada !important;
        }
        &.active{
          span{
            background-color: #9d9c9c !important;
          }
        }
      }
    }
  }
}
//awards area end//

//........about page..........//
.about-area {
  .about-row {
    margin-top: 40px;
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
  }
  .about-img {
    @include media-breakpoint-down(sm) {
      padding-bottom: 30px;
    }
    img {
      width: 100%;
      @include media-breakpoint-down(lg) {
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;
      }
    }
  }
  .about-text {
    margin-top: 15px;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }
  .about-text-area {
    @include media-breakpoint-down(sm) {
      order: 2;
    }
  }
  .about-img {
    @include media-breakpoint-down(sm) {
      order: 1;
    }
  }
}
//......about page end........//

//.....Product List page......//
.pro-page{
  text-align: left;
  .p-card{
    margin-bottom: 30px;
    @include media-breakpoint-down(md){
      margin-bottom: 20px;
    }
  }
  //...Sidebar filter...//
  //price//
  .filter-price {
    margin: 20px 0 30px 0;
    position: relative;
    @include media-breakpoint-down(lg){
      margin: 15px 0 20px 0;
    }
    .price-field {
      position: relative;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      padding: 0;
      input[type=range] {
        position: absolute;
        width: 100%;
        left: 0;
        border: 15px solid #ece4de;
        border-bottom: 20px solid #ece4de;
        outline: 0;
        box-sizing: border-box;
        pointer-events: none;
        -webkit-appearance: none;
      }
      input[type=range]::-webkit-slider-thumb {
        position: relative;
        -webkit-appearance: none;
        margin: 0;
        border: 0;
        outline: 0;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin-top: -4px;
        background-color: #fff;
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
      }
      input[type=range]:active,
      input[type=range]:focus {
        outline: 0;
      }
      input[type=range]::-ms-track {
        width: 188px;
        height: 2px;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        border-radius: 5px;
        pointer-events: none;
        background: transparent;
        border-color: transparent;
        color: $main-clr;
      }
      input[type=range]::-moz-range-thumb {
        position: relative;
        appearance: none;
        margin: 0;
        border: 0;
        outline: 0;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin-top: -5px;
        background-color: #fff;
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
      }
      input[type=range]::-ms-thumb {
        position: relative;
        appearance: none;
        margin: 0;
        border: 0;
        outline: 0;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin-top: -5px;
        background-color: $dark;
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
      }
      input[type=range]::-webkit-slider-runnable-track {
        width: 188px;
        height: 2px;
        cursor: pointer;
        background: $dark;
        border-radius: 5px;
      }
      input[type=range]::-moz-range-track {
        width: 188px;
        height: 2px;
        cursor: pointer;
        background: $dark;
        border-radius: 5px;
      }
      input[type=range]::-ms-track {
        width: 188px;
        height: 2px;
        cursor: pointer;
        background: $dark;
        border-radius: 5px;
      }
      input[type=range]:hover::-webkit-slider-thumb {
        box-shadow: 0 0 0 0.5px $dark;
        transition-duration: 0.3s;
      }
      input[type=range]:active::-webkit-slider-thumb {
        box-shadow: 0 0 0 0.5px $dark;
        transition-duration: 0.3s;
      }
    }
    .price-wrap {
      display: flex;
      color: $dark;
      line-height: 1.2em;
      font-weight: 400;
      margin-bottom: 0;
      label{
        margin: 0;
      }
      .price-title {
        position: relative;
        color: #fff !important;
        font-size: 15px;
        line-height: 1.2em;
        font-weight: 400;
        background-color: $dark;
        padding: 10px !important;
        @include transition;
        &:hover{
          background-color: $main-clr;
        }
      }
      a:hover {
        color: #fff !important;
      }
      .price-container {
        display: flex;
        border: none;
        padding: 20px 15px 5px 15px;
        margin: 0;
        width: 100%;
        justify-content: space-around;
        color: $dark;
        background-color: #ece4de;
        .price-wrap-1,
        .price-wrap_line {
          margin: 0 15px;
          font-size: 20px;
        }
        .price-wrap-1,
        .price-wrap-2 {
          font-size: 16px;
          margin: 0;
          @include media-breakpoint-down(lg){
            font-size: 15px;
          }
        }
      }
    }
  }
  .price-wrap #one,
  .price-wrap #two {
    width: 30px;
    text-align: right;
    margin: 0;
    padding: 0;
    background: 0;
    border: 0;
    outline: 0;
    color: $dark;
    font-size: 16px;
    line-height: 1.2em;
    @include media-breakpoint-down(lg){
      font-size: 15px;
    }
  }
  //price end//
  ul.bottom-filter {
    padding: 35px 25px 15px 25px;
    border: $border-theme;
    @include media-breakpoint-down(lg){
      padding: 25px 15px 15px 15px;
    }
    @include media-breakpoint-down(sm){
      padding: 30px 20px 15px 20px;
      margin-top: 35px;
    }
    li {
      display: inline;
      .input-group{
        width: 100%;
        margin-bottom: 25px;
        @include media-breakpoint-down(lg){
          margin-bottom: 20px;
        }
        .my-search-box{
          color: $dark;
          background-color: #ece4de;
          border: none;
          border-radius: 0;
          font-size: 14.5px;
          height: 46px;
          &:hover, &:active, &:focus{
            background-color: #ece4de;
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
        .my-search-btn{
          background-color: #ece4de;
          padding: 0 10px 0 1px;
          border: none;
          border-radius: 0;
          height: 46px;
          @include media-breakpoint-down(lg){
            padding: 0 7px 0 1px;
          }
          i{
            font-size: 15px;
            color: $dark;
          }
          &:hover, &:active, &:focus{
            background-color: #ece4de;
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $dark;
        padding-bottom: 10px;
        margin-bottom: 25px;
        border-bottom: $border-theme;
        @include media-breakpoint-down(lg){
          font-size: 20px;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }
      }
      h6 {
        font-weight: 500;
        font-size: 18px;
        font-family: 'Castoro', serif;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $dark;
        margin-bottom: 0;
        @include media-breakpoint-down(lg){
          font-size: 17.4px;
          letter-spacing: 0;
        }
      }
      ul.category {
        display: inline-block;
        width: 100%;
        margin: 20px 0;
        @include media-breakpoint-down(lg){
          margin: 15px 0;
        }
        li {
          display: block;
          .form-check-label {
              margin-bottom: -25px;
              input {
                margin-right: 5px;
              }
            }
        }
      }
      .tags-ul{
        padding: 20px 0;
        margin: 0;
        @include media-breakpoint-down(lg){
          padding: 15px 0;
        }
        li{
          display: inline-block;
          margin: 0 8px 8px 0;
          padding: 6px 14px;
          border-radius: 0;
          border: $border-theme;
          background-color: $white;
          @include transition;
          @include media-breakpoint-down(lg){
            padding: 4px 12px;
            margin: 0 6px 6px 0;
          }
          &:hover{
            background-color: $s-main-clr;
            border-color: transparent;
          }
          .tags-a{
            font-size: 16px;
            font-weight: 500;
            color: $dark;
            @include media-breakpoint-down(lg){
              font-size: 15px;
            }
          }
        }
      }
      .my-product{
        padding: 20px 0;
        margin: 0;
        @include media-breakpoint-down(lg){
          padding: 15px 0;
        }
        li{
          .recent-post {
            display: flex;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: $border-theme;
            &:hover{
              .post-details{
                .title{
                  color: $main-clr;
                  @include transition;
                }
              }
            }
            .post-img {
              flex-shrink: 0;
              margin-right: 10px;
              @include media-breakpoint-down(lg) {
                margin-right: 7px;
              }
              img {
                width: 70px;
                @include media-breakpoint-down(lg) {
                  width: 55px;
                }
              }
            }
            .post-details {
              .title {
                font-size: 15px;
                font-weight: 500;
                text-transform: uppercase;
                color: $dark;
                display: flex;
                margin-bottom: 2px;
                text-decoration: none;
                @include transition;
                @include media-breakpoint-down(lg) {
                  font-size: 14.5px;
                }
              }
              p {
                font-size: 13px;
                line-height: 18px;
                margin: 0;
                @include media-breakpoint-down(lg) {
                  font-size: 12.3px;
                  line-height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  //...Sidebar filter end...//
}
//...Product List page end....//

//....Product Details page....//
.pro-details {
  .thumb-carousel {
    .carousel {
      display: flex;
      flex-direction: column-reverse;
      @include media-breakpoint-down(sm){
        flex-direction: column;
        padding-left: 0;
      }
      .carousel-indicators {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        z-index: 8;
        @include media-breakpoint-down(sm){
          justify-content: center;
          order: 1;
          width: 100%;
          padding: 0;
          flex-direction: row;
          overflow: auto !important;
          touch-action: pan-x !important;
          margin-top: 16px;
        }
        li {
          border: 1px solid transparent;
          border-radius: 0;
          display: block;
          height: auto;
          text-indent: 0;
          width: 80px;
          flex-shrink: 0;
          margin: 0 4px;
          margin-bottom: 10px;
          padding: 0;
          @include transition;
          @include media-breakpoint-down(sm){
            margin: 0 2px;
            width: 50px;
          }
          img {
            @include objectfit(80px, $object: contain);
            border: $border-theme;
            padding: 6px;
            @include media-breakpoint-down(sm){
              height: 50px;
              padding: 2px;
            }
          }
          &.active {
            img {
              border-color: $main-clr;
            }
          }
        }
      }
      .carousel-inner {
        border-radius: 0;
        .carousel-item {
          img {
            @include objectfit(376px, $object: contain);
            border-radius: 0;
            @include media-breakpoint-down(md){
              height: 280px;
            }
          }
        }
      }
    }
  }
  .product-details {
    @include media-breakpoint-down(md){
      margin-top: 30px;
    }
    h1 {
      font-size: 34px;
      font-weight: 500;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 8px;
      color: $dark;
      @include media-breakpoint-down(lg){
        font-size: 28px;
      }
      @include media-breakpoint-down(sm){
        font-size: 24px;
      }
    }
    .ul-ratings {
      margin: 0 0 15px 0;
      padding: 0;
      li {
        display: inline-block;
        a {
          font-size: 14.5px;
          letter-spacing: 1px;
          font-weight: 600;
          line-height: 22px;
          color: $main-clr;
          margin-bottom: 50px;
          @include media-breakpoint-down(sm){
            font-size: 13px;
          }
        }
      }
      li.star {
        font-size: 12.5px;
        letter-spacing: 1px;
        padding: 3px 6px 3px 8px;
        margin-right: 5px;
        border-radius:0;
        color: $white;
        background-color: $main-clr;
        i {
          font-size: 9px;
        }
      }
    }
    .stock {
      margin: 25px 0 5px 0;
      @include media-breakpoint-down(md) {
        margin: 10px 0;
      }
      ul {
        padding: 0;
        li {
          display: inline-block;
          a {
            font-size: 18px;
            color: $main-clr;
            font-weight: 600;
          }
          h5 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        }
      }
    }
    .my-span {
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $dark;
    }
    .select-option {
      margin: 25px 0 0 0;
      ul {
        display: flex;
        margin: 15px -4px 0 -4px;
        li {
          padding: 0 4px;
          a {
            display: block;
            min-width: 60px;
            padding: 8px 16px;
            color: $gray900;
            text-align: center;
            border: $border-theme;
            border-radius: 0;
          }
          &.active {
            a {
              background-color: $light-clr;
            }
          }
        }
      }
    }
    .price {
      margin: 25px 0 0 0;
      ul{
        padding: 0;
        margin: 15px 0 0 0;
        @include media-breakpoint-down(sm){
          margin: 10px 0 0 0;
        }
        li {
          display: inline-block;
          margin-right: 10px;
          @include media-breakpoint-down(sm){
            margin-right: 7px;
          }
          h5 {
            font-size: 26px;
            color: $main-clr;
            font-weight: 600;
            @include media-breakpoint-down(sm){
              font-size: 24px;
            }
          }
          del {
            font-size: 17px;
            font-weight: 600;
            color: #cbbeb3;
          }
          h6 {
            font-size: 17px;
            font-weight: 600;
            color: $color-two;
            margin-bottom: 0;
          }
        }
      }
    }
    .form-elements {
      margin: 16px -4px 0;
      li {
        padding: 0 4px;
        .form-group {
          max-width: 180px;
          margin: 0;
          .form-control{
            height: 44px;
            border-radius: 0;
            border: $border-theme !important;
            outline: none !important;
            box-shadow: none !important;
            background-color: $light-clr;
            @include media-breakpoint-down(lg){
              height: 38px;
            }
          }
        }
      }
    }
    .checkout-btn{
      padding: 0;
      margin: 25px 0 0 0;
      @include media-breakpoint-down(sm){
        margin: 15px 0 0 0;
      }
      li{
        display: inline-block;
        margin-right: 10px;
        @include media-breakpoint-down(sm){
          margin: 10px 5px 0 0;
        }
      }
    }
  }
}
.description {
  .tab-area {
    .nav-tabs {
      padding-bottom: 10px;
      .nav-item {
        &:not(:last-child) {
          padding: 0 8px 8px 0;
        }
        .nav-link {
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          display: flex;
          padding: 12px 24px;
          border: $border-theme;
          border-radius: 0;
          color: $dark;
          background-color: $light-clr;
          @include transition;
          @include media-breakpoint-down(md){
            font-size: 11.5px;
            padding: 10px 18px;
          }
          &.active {
            color: $white;
            background-color: $main-clr;
            border: $border-main;
          }
        }
      }
    }
    .tab-pane {
      padding: 32px 0 0;
    }
  }
}
.review-list {
  max-height: 480px;
  overflow-y: auto;
  padding: 0 16px 0 0;
  > ul {
    > li {
      padding: 16px 0;
      border-bottom: $border;
      margin-bottom: -1px;
      .reviews-img{
        padding: 0;
        margin: 0 0 15px 0;
        li{
          display: inline-block;
          img{
            width: 75px;
            padding: 3px;
            background-color: #e8e1dc;
            margin-top: -60px;
          }
          .reviews-name{
            padding: 0;
            margin: 0 0 0 15px;
            li{
              display: block;
              list-style-type: none;
              h4 {
                font-size: 17px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $dark;
              }
              h5 {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
//..Product Details page end..//

//.....shopping cart page.....//
.shopping-cart {
  width: 100%;
  overflow: hidden;
  text-align: center;
  .my-table {
    background-color: $white;
    border: none;
    margin-bottom: 0;
    .table {
      margin-bottom: 0;
      .thead-dark {
        th {
          color: $dark;
          background-color: $light-clr;
          border: none;
          padding: 20px 30px;
          font-size: 17px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          @include media-breakpoint-down(sm){
            padding: 20px 24px;
          }
        }
      }
      .my-tbody {
        .my-tr{
          background-color: #f9f7f6;
        }
        th {
          border: none;
          padding: 20px 0;
          img {
            height: 110px;
            border: $border-theme;
            padding: 10px;
            @include media-breakpoint-down(md) {
              height: 80px;
            }
          }
        }
        td {
          padding-top: 50px;
          border: none;
          h4 {
            font-size: 19px;
            font-weight: 600;
            font-family: 'Lato', sans-serif;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $main-clr;
            margin-bottom: 5px;
            @include media-breakpoint-down(md) {
              font-size: 18px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 16.5px;
            }
          }
          h6 {
            color: $dark;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }
          .my-h6{
            line-height: 26px;
          }
          h5 {
            color: $dark;
            font-size: 17px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
          }
          a {
            font-size: 17px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $dark;
            @include transition;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
            i {
              font-size: 20px;
              color: $dark;
              @include transition;
              @include media-breakpoint-down(md) {
                font-size: 18px;
              }
              @include media-breakpoint-down(sm) {
                font-size: 16px;
              }
            }
          }
          .cut {
            img{
              width: 10px;
              padding-top: 20px;
              @include media-breakpoint-down(md){
                padding-top: 0;
                width: 8px;
              }
            }
          }
        }
        td.my-quantity {
          input {
            color: $dark;
            font-weight: 600;
            overflow: visible;
            width: 150px;
            padding: 10px 15px;
            border: $border-theme;
          }
          input:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.checkout-btn {
  text-align: left;
  padding: 40px 0 0 0;
  margin: 0;
  @include media-breakpoint-down(sm) {
    padding: 20px 0 0 0;
  }
  li {
    display: inline-block;
    margin-right: 15px;
    @include media-breakpoint-down(sm) {
      margin-right: 10px;
      margin-top: 10px;
    }
    .read-more{
      font-size: 14px;
      padding: 12px 30px;
    }
  }
}
//...shopping cart page end...//

//........News Details.......//
.news-details-area{
  .my-form{
    padding: 35px 20px;
    border: $border-theme;
    @include media-breakpoint-down(lg) {
      padding: 25px 15px;
    }
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
    @include media-breakpoint-down(sm) {
      padding: 25px 15px;
    }
  }
  .right-area {
    padding-left: 15px;
    @include media-breakpoint-down(lg){
      padding-left: 10px;
    }
    @include media-breakpoint-down(md){
      padding-top: 30px;
      padding-left: 0;
    }
    ul {
      margin: 0 0 40px 0;
      padding: 0;
      .post-heading-li{
        margin: 0;
        padding: 0;
        border: none;
        .post-heading {
          font-size: 24px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 2px;
          margin-bottom: 30px;
          color: $dark;
        }
      }
      li {
        padding: 0 0 20px 0;
        list-style: none;
        margin-bottom: 20px;
        border-bottom: $border-theme;
        .recent-post {
          display: flex;
          &:hover{
            .post-details{
              .title{
                color: $main-clr;
                @include transition;
              }
            }
          }
          .post-img {
            flex-shrink: 0;
            margin-right: 20px;
            @include media-breakpoint-down(lg) {
              margin-right: 10px;
            }
            img {
              width: 110px;
              height: 100%;
              object-fit: cover;
              object-position: top;
              @include media-breakpoint-down(lg) {
                width: 90px;
              }
            }
          }
          .post-details {
            .title {
              font-size: 16px;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 1px;
              color: $dark;
              display: flex;
              margin-bottom: 1px;
              text-decoration: none;
              @include transition;
              @include media-breakpoint-down(lg) {
                font-size: 16px;
              }
            }
            p {
              font-size: 16px;
              line-height: 20px;
              margin: 8px 0;
              @include media-breakpoint-down(sm) {
                font-size: 14px;
                margin: 5px 0;
              }
            }
            .date {
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              color: $dark;
              letter-spacing: 2px;
              display: block;
              margin: 0;
            }
          }
        }
        .tags-ul{
          padding: 0;
          margin: 0;
          li{
            display: inline-block;
            margin: 0 5px 5px 0;
            border: none;
            .tags-a{
              font-size: 16px;
              font-weight: 500;
              color: $dark;
              padding: 8px 16px;
              border: $border-theme;
              background-color: $white;
              @include transition;
              @include media-breakpoint-down(sm){
                font-size: 14px;
                padding: 8px 12px;
              }
              &:hover{
                color: $white;
                background-color: $main-clr;
                border-color: transparent;
              }
            }
          }
        }
      }
    }
    .login-area{
      @include media-breakpoint-down(md){
        width: 50%;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
  }
}
//......News Details end.....//

//.............FAQ..........//
.faq-area{
  width: 100%;
  position: relative;
  .content{
    padding: 40px 32px;
    border: $border-theme;
    border-radius: 0;
    background-color: $white;
    @include media-breakpoint-down(sm){
      padding: 24px 16px;
    }
    h2{
      font-size: 32px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: $dark;
      margin-bottom: 8px;
      @include media-breakpoint-down(md){
        font-size: 24px;
      }
    }
    .accordion {
      margin-bottom: 20px;
      .card{
        border: none;
        border-radius: 0;
        flex-direction: column;
        padding: 0;
        margin-top: 20px;
        a.card-header{
          padding: 20px 0;
          border: none;
          background-color: transparent;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 1px;
          color: $dark;
          @include media-breakpoint-down(md){
            font-size: 16px;
          }
          &:hover{
            text-decoration: none;
          }
          span{
            font-weight: 600;
            color: $main-clr;
            margin-right: 4px;
            font-size: 18px;
            @include media-breakpoint-down(md){
              font-size: 16px;
            }
          }
        }
        .card-body{
          border: none;
          background-color: transparent;
          p{
            margin-bottom: 0;
          }
        }
        .faq-ul{
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: $border-theme;
          li{
            display: flex;
            justify-content: space-between;
            i{
              font-size: 20px;
              color: $main-clr;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
}
//...........FAQ END........//

//.....conditions area.....//
.conditions-area{
  .conditions{
    width: 100%;
    padding-bottom: 20px;
    h4{
      font-weight: 500;
      color: $dark;
      margin-bottom: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      @include media-breakpoint-down(md){
        font-size: 20px;
      }
    }
    p{
      margin-bottom: 12px;
    }
  }
}
//...conditions area end...//

//.......orders area.......//
.orders-area{

}
//.....orders area end.....//