//my form//
.my-form {
  padding: 45px 35px;
  border: $border-theme;
  border-radius: 0;
  background-color: $light-clr;
  @include media-breakpoint-down(lg) {
    padding: 35px 20px;
  }
  @include media-breakpoint-down(sm) {
    padding: 25px 15px;
  }
  .form-group{
    margin-bottom: 0;
    h5 {
      color: $dark;
      font-size: 24px;
      font-weight: 400;
      font-family: 'Castoro', serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
    }
    label{
      font-size: 16px;
      font-weight: 600;
      &.required{
        &::after{
          content: "*";
          position: relative;
          margin-left: 5px;
          color: red;
        }
      }
    }
    .form-control{
      margin-bottom: 20px;
      height: 56px;
      box-shadow: none;
      outline: none;
      border-radius:0;
      border:$border-theme;
      background-color: $white !important;
      font-size: 16px;
      color: #6c757d;
      &:focus{
        outline: none;
        border-color: $main-clr;
      }
    }
    textarea{
      &.form-control{
        resize: none;
        height:auto;
      }
    }
  }
  .form-check {
    margin-bottom: 20px;
    .form-check-label {
      a {
        font-weight: 500;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }
  .form-link {
    margin-top: 20px;
    a {
      color: $color-one;
    }
  }
}
//my form end//