.p-card {
  text-align: center;
  background-color: $white;
  padding: 15px;
  border:1px solid transparent;
  @include transition;
  &:hover{
    border: $border-theme;
    .p-card-text{
      a{
        h5{
          color: $main-clr;
        }
      }
    }
  }
  img {
    width: 100%;
  }
  .p-card-text {
    margin-top: 15px;
    @include media-breakpoint-down(lg){
      margin-top: 10px;
    }
    a {
      text-decoration: none;
      h5 {
        font-size: 19px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $dark;
        margin-bottom: 15px;
        @include transition;
        @include media-breakpoint-down(lg) {
          font-size: 17px;
          margin-bottom: 10px;
        }
        &:hover{
          color: $main-clr;
        }
      }
    }
    h6{
      font-size: 19px;
      font-weight: 600;
      color: $p-clr;
      margin-bottom: 0;
      @include media-breakpoint-down(lg) {
        font-size: 17px;
      }
    }
  }
}