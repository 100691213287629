//Set your custom variable
$main-clr:         #ab9583;
$s-main-clr:       #c5b2a3;
$l-main-clr:       #1f282d;
$light-clr:        #f2eeeb;
$p-clr:            #666666;
$dark:             #010000;
$primary-color:    #c5b2a3;
$secondary-color:  #fab101;
$color-one:        #ea3339;
$color-two:        #65af0b;
$black:            #000000;
$white:            #ffffff;
$gray100:          #F7FAFC;
$gray200:          #EDF2F7;
$gray300:          #E2E8F0;
$gray400:          #CBD5E0;
$gray500:          #A0AEC0;
$gray600:          #718096;
$gray700:          #4A5568;
$gray800:          #2D3748;
$gray900:          #1A202C;
$border-radius-full:100%;
$border-radius-half:50%;
$border-radius-custom:5px;
$border:1px solid #E2E8F0;
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-inner:inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
//border theme//
$border-theme:  1px solid #e8e1dc;
$border-black:  1px solid $black;
$border-green:  1px solid $s-main-clr;
$border-main:  1px solid $main-clr;
//shadow theme//
$pro-shadow:     0 5px 20px #00000026;