//nav//
header {
  width: 100%;
  z-index: 10;
  .nav_area {
    width: 100%;
    background-color: $white;
    nav.navbar {
      width: 100%;
      padding: 0;
      @include media-breakpoint-down(md) {
        padding: 15px 0 18px 0;
      }
      #logo {
        .my-logo {
          width: 270px;
          @include media-breakpoint-down(lg) {
            width: 240px;
          }
          @include media-breakpoint-down(md) {
            width: 220px;
            order: 1;
          }
          @include media-breakpoint-down(sm) {
            width: 170px;
          }
        }
      }
      .navbar-toggler {
        padding: 0 0 0 4px;
        border: none;
        .navbar-toggler-icon {
          width: 24px;
          height: 18px;
          background-image: none;
          position: relative;
          border-bottom: 2px solid $gray700;
          @include transition;
          @include media-breakpoint-down(sm) {
            width: 21px;
          }
          &::after, &::before {
            width: 24px;
            position: absolute;
            height: 2px;
            background-color: $gray700;
            top: 0;
            left: 0;
            content: '';
            z-index: 2;
            @include transition;
            @include media-breakpoint-down(sm) {
              width: 21px;
            }
          }
          &::after {
            top: 8px;
          }
        }
        &[aria-expanded="true"] {
          .navbar-toggler-icon {
            border-color: transparent;
            &::after {
              transform: rotate(45deg);
            }
            &::before {
              transform: translateY(8px) rotate(-45deg);
            }
          }
        }
      }
      button:focus {
        outline: none;
      }
      .collapse {
        justify-content: flex-end;
        padding-top: 10px;
        @include media-breakpoint-down(md) {
          order: 4;
        }
      }
      .navbar-nav {
        .nav-link {
          @include media-breakpoint-down(md) {
            margin-top: 10px;
          }
        }
      }
      .nav-link {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $dark;
        padding: 15px 0 25px 0;
        margin-left: 30px;
        transition: all 0.3s;
        @include media-breakpoint-down(lg) {
          margin-left: 25px;
        }
        @include media-breakpoint-down(md) {
          font-size: 15px;
          padding: 0;
          margin-left: 0;
        }
        &.active, &:active, &:focus, &:hover {
          color: $main-clr !important;
          transition: all 0.3s;
        }
      }
    }
    .icon-area {
      margin-top: 15px;
      @include media-breakpoint-down(md) {
        order: 2;
        position: absolute;
        right: 3%;
        top: 12px;
      }
      @include media-breakpoint-down(sm) {
        position: absolute;
        right: 7%;
        top: 6px;
      }
      h2 {
        @include media-breakpoint-down(md) {
          margin-right: 25px;
        }
        @include media-breakpoint-down(sm) {
          margin-right: 15px;
        }
        a {
          img {
            height: 20px;
            @include media-breakpoint-down(sm) {
              height: 18px;
            }
          }
        }
      }
    }
    //dropdown-menu//
    .dropdown {
      position: static;
      &:hover {
        .dropdown-menu {
          display: block;
          visibility: visible;
          opacity: 1;
          transition: visibility 0s, opacity 0.3s linear;
        }
      }
      a.nav-link.dropdown-toggle {
        color: $dark;
      }
      .dropdown-menu {
        border-top: 3px solid $s-main-clr;
        padding: 20px;
        width: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 88%;
        border-radius: 0;
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s linear;
        @include media-breakpoint-down(md) {
          position: relative;
          display: none;
        }
        ul.my-ul {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 0 20px;
          @include media-breakpoint-down(md) {
            padding: 0;
          }
          @include media-breakpoint-down(sm) {
            display: block;
          }
          .my-li {
            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
          li {
            list-style: none;
            text-align: left;
          }
          h3 {
            color: $main-clr !important;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
            padding-bottom: 4px;
            margin-bottom: 15px;
            border-bottom: $border-theme;
            @include media-breakpoint-between(sm, lg) {
              font-size: 15px;
              padding-bottom: 2px;
              margin-bottom: 12px;
            }
          }
          ul {
            padding-left: 0;
            li {
              a.nav-link {
                font-size: 14px;
                color: $dark;
                background-color: $white;
                margin: 0;
                padding: 0 0 10px 0;
                @include transition;
                @include media-breakpoint-down(md) {
                  font-size: 12.5px;
                }
                &:hover {
                  color: $main-clr !important;
                  margin-left: 5px;
                }
              }
            }
          }
        }
        &:hover {
          display: block;
          visibility: visible;
          opacity: 1;
          transition: visibility 0s, opacity 0.3s linear;
        }
      }
    }
    //dropdown-menu end//
  }
}
//nav end//