.read-more {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 3px;
  color: $white !important;
  background-color: $dark;
  padding: 12px 42px;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius:0;
  box-shadow: none;
  transition: all 0.7s;
  @include transition;
  @include media-breakpoint-down(lg) {
    font-size: 14px;
    padding: 9px 32px;
    letter-spacing: 2px;
  }
  &.active, &:focus, &:hover {
    background-color: $main-clr;
  }
}