//slide//
#my-slider {
  transition: all 0.9s;
  .carousel-indicators{
    bottom: 15px;
    @include media-breakpoint-down(lg){
      bottom: 10px;
    }
    @include media-breakpoint-down(sm){
      bottom: -2px;
    }
    li{
      background-color: #bdbdbd;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      @include media-breakpoint-down(sm){
        width: 6px;
        height: 6px;
      }
      &.active{
        background-color: #9d9c9c;
      }
    }
  }
  .carousel-item{
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(lg){
      height: 470px;
    }
    @include media-breakpoint-down(md){
      height: 350px;
    }
    @include media-breakpoint-down(sm){
      height: 160px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .carousel-caption {
    text-align: left;
    top: 20%;
    left: 6%;
    width: 50%;
    z-index: 5;
    @include media-breakpoint-down(lg) {
      width: 60%;
    }
    @include media-breakpoint-down(md) {
      top: 18%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
      top: 1%;
      left: 8%;
    }
    h2 {
      font-size: 125px;
      line-height: 115px;
      font-weight: 900;
      font-family: 'Herr Von Muellerhoff', cursive; //  for Slider only  //
      color: $main-clr;
      margin-bottom: 25px;
      @include media-breakpoint-down(lg) {
        font-size: 95px;
        line-height: 85px;
        margin-bottom: 15px;
      }
      @include media-breakpoint-down(md) {
        font-size: 70px;
        line-height: 65px;
        margin-bottom: 8px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 5px;
      }
    }
    p{
      font-size: 22px;
      font-weight: 500;
      color: #232323;
      letter-spacing: 1px;
      @include media-breakpoint-down(lg){
        font-size: 18px;
      }
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
      @include media-breakpoint-down(sm){
        font-size: 12.5px;
        line-height: 14px;
        letter-spacing: 0.5px;
      }
    }
  }
  .carousel-control-next{
    position: absolute;
    top: 40%;
    right: 30px;
    width: 30px;
    height: 30px;
    opacity: 1;
    @include media-breakpoint-down(lg){
      right: 15px;
    }
    @include media-breakpoint-down(sm){
      right: 0;
    }
  }
  .carousel-control-prev{
    position: absolute;
    top: 40%;
    left: 30px;
    width: 30px;
    height: 30px;
    opacity: 1;
    @include media-breakpoint-down(lg){
      left: 15px;
    }
    @include media-breakpoint-down(sm){
      left: 0;
    }
  }
  .my-icon {
    background: transparent;
    img{
      height: 26px;
      @include media-breakpoint-down(md){
        height: 20px;
      }
    }
  }
}
//slide end//