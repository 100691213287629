//breadcrumb//
.breadcrumb-wrapper{
    padding: 15px;
    border-radius: 0;
    background-color: $light-clr;
    margin: 10px 0 40px 0;
    @include media-breakpoint-down(md){
        margin: 0 0 30px 0;
        padding: 12px 15px;
    }
    .breadcrumb{
        background-color: transparent;
        padding: 0;
        margin: 0;
        li{
            font-size: 18px;
            font-family: 'Castoro', serif;
            @include media-breakpoint-down(md){
                font-size: 16px;
            }
            a{
                color:$dark;
                font-size: 18px;
                font-family: 'Castoro', serif;
                @include media-breakpoint-down(md){
                    font-size: 16px;
                }
                svg{
                    margin-right: 8px;
                    height: 18px;
                    width: 18px;
                    position: relative;
                    top: -2px;
                    @include media-breakpoint-down(md){
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            &.active{
                color: $main-clr;
            }
            + li{
                &::before{
                    color: $dark !important;
                }
            }
        }
    }
}
//breadcrumb end//