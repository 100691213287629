.my-heading {
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 34px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $dark;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      font-size: 28px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 26px;
    }
  }
}